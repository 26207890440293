import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import {
	SearchParams,
	SearchSkills,
	ProgAISearchResult,
	SearchHistoryRequest,
	SearchHistory
} from 'shared/generated-models';

export const searchService = createApi({
	reducerPath: 'searchService',
	baseQuery: interceptor,
	tagTypes: ['Search', 'SearchHistory'],
	endpoints: builder => ({
		getCandidates: builder.query<ProgAISearchResult, SearchParams>({
			query: params => ({
				url: '/search/',
				method: 'POST',
				body: params,
				invalidatesTags: ['Search']
			})
		}),
		uploadText: builder.mutation<SearchSkills, { text: string }>({
			query: params => ({
				url: '/upload_text/',
				method: 'POST',
				body: params
			})
		}),
		uploadFile: builder.mutation<SearchSkills, Blob>({
			query: file => {
				const data = new FormData();
				data.append('file', file);

				return {
					url: '/upload_file/',
					method: 'POST',
					body: data
				};
			}
		}),
		saveHistory: builder.mutation<void, SearchHistoryRequest>({
			query: params => ({
				url: '/save_search/',
				method: 'POST',
				body: params
			})
		}),
		getSearchHistory: builder.query<SearchHistory[], void>({
			query: params => ({
				url: '/save_search/',
				method: 'GET',
				body: params,
				invalidatesTags: ['SearchHistory']
			}),
			transformResponse(items: SearchHistory[]) {
				return items.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
			}
		})
	})
});

export const {
	useGetCandidatesQuery,
	useUploadTextMutation,
	useUploadFileMutation,
	usePrefetch,
	useSaveHistoryMutation,
	useGetSearchHistoryQuery
} = searchService;
