import React, { FC } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';
import cn from 'classnames';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, FormikInput, Icon, Text } from 'shared/components/ui';
import { createProjectFormValidationSchema } from 'features/projects/models';
import { useCreateProjectMutation, useUpdateProjectMutation } from 'services';
import { TopIdsWithFiltersCandidates, ProjectCreate, ProjectWithCounters } from 'shared/generated-models';
import { useFilters } from 'features/search/hooks';
import { Analytics } from 'features/analytics';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	project?: ProjectWithCounters | null;
	candidates?: Omit<TopIdsWithFiltersCandidates, 'filters'> & {
		count: number;
	};
}

export const CreateProjectModal: FC<Props> = ({ onClose, project, candidates }: Props) => {
	const [createProject, { isLoading }] = useCreateProjectMutation();
	const [updateProject] = useUpdateProjectMutation();

	const filters = useFilters();

	const maxToAdd = project ? project.possible_to_add : 50000;

	const formik = useFormik<ProjectCreate>({
		validationSchema: !project && createProjectFormValidationSchema,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: { name: '' },
		onSubmit: async values => {
			try {
				if (project) {
					await updateProject({
						name: project.name,
						candidates: { ...candidates, filters },
						status: project.status,
						id: project.id
					}).unwrap();
					Analytics.trackAddingCandidatesToProject({
						projectId: project.id,
						candidatesCount: candidates?.count
					});
				} else if (candidates) {
					await createProject({ name: values.name, candidates: { ...candidates, filters } }).unwrap();
					Analytics.trackAddingCandidatesToProject({ candidatesCount: candidates?.count });
				} else {
					await createProject(values).unwrap();
				}
				onClose();
			} catch (e: any) {
				if (e.data && e.data.name) {
					formik.setErrors({ name: 'Project with this name already exists' });
					return;
				}
			}
		}
	});

	return (
		<CenterModal
			onClose={onClose}
			icon={project ? 'folder' : 'add-folder'}
			title={project ? 'Save to project' : 'New project'}
			iconColor="green">
			<div className={styles.body}>
				{project ? (
					<div className={styles.project}>
						<div className={styles.projectTop}>
							<Icon icon="folder" className={styles.projectIcon} size={20} />
							<Text variant="inter/14/medium">{project.name}</Text>
						</div>
						<Text variant="inter/14/regular" color="grey" className={styles.projectBottom}>
							{isPlural(project.added) ? `${project.added} candidates` : `${project.added} candidate`}
						</Text>
					</div>
				) : (
					<FormikProvider value={formik}>
						<Form layout="vertical">
							<FormikInput name="name" size="large" placeholder="Project name" label="Project name" />
						</Form>
					</FormikProvider>
				)}
				{candidates && (
					<div
						className={cn(styles.count, {
							[styles.countMargin]: !project,
							[styles.countError]: formik.errors.name
						})}>
						<Icon icon="person" className={styles.countIcon} />
						<Text variant="inter/14/medium">
							{candidates.count} {isPlural(candidates.count) ? 'candidates' : 'candidate'}
						</Text>
					</div>
				)}
			</div>
			<ModalFooter>
				<Button onClick={onClose} type="ghost">
					Cancel
				</Button>
				<Button
					onClick={formik.handleSubmit}
					type="primary"
					disabled={
						(!formik.isValid && !project) ||
						isLoading ||
						(!!project && !!candidates && maxToAdd < candidates.count) ||
						(!!candidates && maxToAdd < candidates.count)
					}>
					{!!project && 'Save'}
					{!project && candidates && 'Create and add'}
					{!project && !candidates && 'Create'}
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
