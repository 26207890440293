import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import { parseFilters } from 'shared/utils';
import { Icon, Text } from 'shared/components/ui';
import { SearchFormV2, SearchSelectV2, SearchTag, SearchText } from 'features/search/components';
import { useGetCountriesQuery, useGetLocationsQuery } from 'services';
import { SearchFormParams } from 'features/search/models';
import { formIsEmpty } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	isOpen: boolean;
	handleClick: () => void;
}

export const SearchCard: FC<Props> = ({ isOpen, handleClick }: Props) => {
	const [tags, setTags] = useState<[string, string][]>([]);

	const { values, setValues, setFieldValue, handleSubmit } = useFormikContext<SearchFormParams>();

	const buttonsDisabled = formIsEmpty({ ...values, size: undefined, page: undefined });

	useEffect(() => {
		const tags: [string, string][] = parseFilters(values);
		setTags(tags);
	}, [values]);

	const handleTextChange = (value: string) => {
		if (value.trim() === '') {
			setFieldValue('text', undefined);
		} else {
			setFieldValue('text', value);
		}
	};

	const handleShowCandidates = () => {
		if (buttonsDisabled) return;
		handleSubmit();
	};

	const handleRemoveFilter = (tag: string, filter: string) => (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		if (filter in ['likely_to_move_prob', 'is_top_school', 'is_first_name_female']) {
			//@ts-ignore
			newValues[filter as keyof SearchParams] = undefined;
		} else {
			if (
				filter in values &&
				//@ts-ignore
				Array.isArray(values[filter as keyof SearchParams]) &&
				//@ts-ignore
				(values[filter as keyof SearchParams] as any[]).length > 1
			) {
				// @ts-ignore
				newValues[filter as keyof SearchParams] = values[filter].filter(val => val !== tag);
			} else {
				if (filter === 'companies') newValues['is_current_companies'] = undefined;
				if (filter === 'companies_to_exclude') newValues['is_current_companies_to_exclude'] = undefined;
				//@ts-ignore
				newValues[filter as keyof SearchParams] = undefined;
			}
		}
		setValues(newValues, false);
	};

	return (
		<Form layout="vertical" className={cn(styles.form, { [styles.formOpen]: isOpen })}>
			<div className={styles.sticky}>
				<div className={cn(styles.formWrapper, { [styles.formWrapperOpen]: isOpen })}>
					<div className={styles.inline}>
						<div className={styles.formTitle}>
							<Text variant="inter/22/medium">I am looking for...</Text>
						</div>
					</div>
					<div className={styles.section}>
						<SearchText isOpen={isOpen} value={values.text} onChange={handleTextChange} variant="main" />
						<div className={styles.row}>
							<SearchSelectV2
								isOpen={isOpen}
								label="City or state"
								name="locations"
								useQuery={useGetLocationsQuery}
								placeholder="City or state"
								icon="location"
							/>
							<SearchSelectV2
								isOpen={isOpen}
								label="Country"
								name="countries"
								useQuery={useGetCountriesQuery}
								placeholder="Country"
								icon="globe"
							/>
							<div
								onClick={handleShowCandidates}
								className={cn(styles.button, { [styles.buttonDisabled]: buttonsDisabled })}>
								<Text variant="inter/15/semi" color="white">
									Show Candidates
								</Text>
							</div>
						</div>
					</div>
				</div>
				{!isOpen && tags.length > 0 && (
					<div className={styles.tags}>
						<>
							{tags.map(([tag, filter]) => (
								<SearchTag
									card={true}
									key={`${filter}: ${tag}`}
									text={tag}
									onClick={handleRemoveFilter(tag, filter)}
								/>
							))}
						</>
					</div>
				)}
				<div
					className={cn(styles.filtersWrapper, { [styles.filtersWrapperOpen]: isOpen })}
					onClick={handleClick}>
					<div className={styles.filters}>
						<Icon icon="filter" size={20} className={styles.filtersIcon} />
						<Text variant="inter/15/medium" color="blue_new">
							All Filters
						</Text>
						<Icon
							icon="vector-down"
							size={12}
							className={cn(styles.filtersIcon, { [styles.filtersIconOpen]: isOpen })}
						/>
					</div>
				</div>
			</div>
			<div className={styles.open}>{isOpen && <SearchFormV2 />}</div>
		</Form>
	);
};
