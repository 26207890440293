import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { useGetCurrentUserQuery } from 'services';
import { ConfiguredTooltip, Icon, Text, TextButton } from 'shared/components/ui';
import { ContactModal, ProfileVariant, useOpenContacts } from 'features/candidate';
import { useGetCandidateProfileQuery } from 'services';
import { Analytics } from 'features/analytics';
import { ProgAICandidate } from 'shared/generated-models';
import { NoCreditsModal } from 'features/auth/components';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
	variant: ProfileVariant;
}

const ITEMS_TO_SHOW_EMAIL = 1;

export const CandidateContacts: FC<Props> = ({ data, variant }: Props) => {
	const id = variant === 'projects' ? data.prog_ai_id : data.id;

	const [isOpen, setIsOpen] = useState(false);

	const { data: user } = useGetCurrentUserQuery();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [notEnoughCreditsModalOpen, setNotEnoughCreditsModalOpen] = useState(false);
	const [skip, setSkip] = useState(true);

	const { isFetching } = useGetCandidateProfileQuery(id!, { skip });

	const [, { isLoading }] = useOpenContacts(variant);

	useEffect(() => {
		variant === 'search' && isLoading && setSkip(false);
	}, [isLoading]);

	const primaryEmail = data.primary_email;

	// const emailsWithoutPrimary = useMemo(
	// 	() => (data.emails || []).filter(email => email.toLowerCase() !== primaryEmail?.toLowerCase()),
	// 	[data]
	// );

	const emailsWithoutPrimary = useMemo(() => {
		const primary = primaryEmail?.toLowerCase();
		const uniqueEmails = new Set<string>();

		(data.emails || []).forEach(email => {
			const lowercasedEmail = email.toLowerCase();
			if (lowercasedEmail !== primary && !uniqueEmails.has(lowercasedEmail)) {
				uniqueEmails.add(lowercasedEmail);
			}
		});

		return Array.from(uniqueEmails);
	}, [data, primaryEmail]);

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	const handleGithubClick = () => {
		Analytics.trackGithubClick(id!, data.github_url!);
	};

	const handleLinkedinClick = () => {
		Analytics.trackLinkedinClick(id!, data.linkedin_url!);
	};

	const handleTwitterClick = () => {
		Analytics.trackTwitterClick(id!, data.twitter_url!);
	};

	const handleFacebookClick = () => {
		Analytics.trackFacebookClick(id!, data.facebook_url!);
	};

	const handleStackoverflowClick = () => {
		Analytics.trackStackoverflowClick(id!, data.stackoverflow_url!);
	};

	const handleKaggleClick = () => {
		Analytics.trackKaggleClick(id!, data.kaggle_handle!);
	};

	const handleHomepageClick = () => {
		Analytics.trackHomepageClick(id!, data.homepage_url!);
	};

	const handleEmail = (email: string) => {
		Analytics.trackEmailCopy(id!, email);
	};

	const handlePhone = (phone: string) => {
		Analytics.trackPhoneCopy(id!, phone);
	};

	const openModal = () => {
		if (user && user.credits < 1) {
			setNotEnoughCreditsModalOpen(true);
		} else {
			setIsModalOpen(true);
		}
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const closeIsNotEnoughCreditsModalOpen = () => {
		setNotEnoughCreditsModalOpen(false);
	};

	const handlePrimaryEmail = () => {
		handleEmail(primaryEmail!);
	};

	if (data.emails)
		return (
			<div className={cn(styles.contacts, styles.contactsOpen)}>
				<div className={styles.contactsSocial}>
					{data.github_handle && (
						<a
							onClick={handleGithubClick}
							className={styles.iconGithub}
							href={`https://github.com/${data.github_handle}`}
							target="_blank"
							rel="noreferrer">
							<Icon icon="github-logo" size={20} />
						</a>
					)}
					{data.linkedin_url && (
						<a
							onClick={handleLinkedinClick}
							className={styles.iconLinkedin}
							href={data.linkedin_url}
							target="_blank"
							rel="noreferrer">
							<Icon icon="linkedin-logo" size={20} />
						</a>
					)}
					{data.twitter_url && (
						<a
							onClick={handleTwitterClick}
							className={styles.iconTwitter}
							href={data.twitter_url}
							target="_blank"
							rel="noreferrer">
							<Icon icon="twitter-logo" size={20} />
						</a>
					)}
					{data.facebook_url && (
						<a
							onClick={handleFacebookClick}
							className={styles.iconFacebook}
							href={data.facebook_url}
							target="_blank"
							rel="noreferrer">
							<Icon icon="facebook-logo" size={20} />
						</a>
					)}
					{data.stackoverflow_url && (
						<a
							onClick={handleStackoverflowClick}
							href={data.stackoverflow_url}
							target="_blank"
							rel="noreferrer">
							<Icon icon="stackoverflow-logo" size={20} />
						</a>
					)}
					{data.kaggle_handle && (
						<a
							onClick={handleKaggleClick}
							href={`https://kaggle.com/${data.kaggle_handle}`}
							target="_blank"
							rel="noreferrer">
							<Icon icon="kaggle" size={20} />
						</a>
					)}
					{data.homepage_url && (
						<a
							onClick={handleHomepageClick}
							className={styles.iconWebsite}
							href={data.homepage_url}
							target="_blank"
							rel="noreferrer">
							<Icon icon="website" size={20} />
						</a>
					)}
				</div>
				{(primaryEmail || emailsWithoutPrimary.length > 0) && (
					<div className={styles.contactsMail}>
						<Icon icon="mail" />
						<ul className={styles.contactsList}>
							{primaryEmail && (
								<li key={primaryEmail}>
									<a
										className={styles.contactsListPrimary}
										href={`mailto:${primaryEmail}`}
										onCopy={handlePrimaryEmail}
										onClick={handlePrimaryEmail}>
										<Text variant="inter/14/medium" color="light_blue">
											{primaryEmail}
										</Text>
									</a>
								</li>
							)}
							{emailsWithoutPrimary.slice(0, ITEMS_TO_SHOW_EMAIL).map(item => (
								<li key={item}>
									<a
										href={`mailto:${item}`}
										onCopy={() => handleEmail(item)}
										onClick={() => handleEmail(item)}>
										<Text variant="inter/14/medium" color="light_blue">
											{item}
										</Text>
									</a>
								</li>
							))}
							{isOpen &&
								emailsWithoutPrimary
									.slice(ITEMS_TO_SHOW_EMAIL, emailsWithoutPrimary.length)
									.map(item => (
										<li key={item}>
											<a
												href={`mailto:${item}`}
												onCopy={() => handleEmail(item)}
												onClick={() => handleEmail(item)}>
												<Text variant="inter/14/medium" color="light_blue">
													{item}
												</Text>
											</a>
										</li>
									))}
						</ul>
					</div>
				)}
				{data.phone_numbers && data.phone_numbers.length > 0 && (
					<div className={styles.contactsMail}>
						<Icon icon="phone" />
						<ul className={styles.contactsList}>
							{data.phone_numbers.map(item => (
								<li key={item}>
									<a onCopy={() => handlePhone(item)} onClick={() => handlePhone(item)}>
										<Text variant="inter/14/medium" color="light_blue">
											{item}
										</Text>
									</a>
								</li>
							))}
						</ul>
					</div>
				)}
				{emailsWithoutPrimary && emailsWithoutPrimary.length > ITEMS_TO_SHOW_EMAIL && (
					<div className={styles.more}>
						<TextButton onClick={handleOpen}>
							{isOpen
								? 'See less'
								: `Show all (${
										emailsWithoutPrimary.length +
										1 +
										(data.phone_numbers ? data.phone_numbers.length : 0)
								  })`}
						</TextButton>
					</div>
				)}
			</div>
		);

	return (
		<>
			<ConfiguredTooltip isOpen={false}>
				<button className={styles.contactsClosed} onClick={openModal}>
					<div className={styles.contactsSocial}>
						<Icon icon="github-logo" size={24} />
						<Icon icon="linkedin-logo" size={24} />
						<Icon icon="twitter-logo" size={24} />
						<Icon icon="facebook-logo" size={24} />
						<Icon icon="stackoverflow-logo-grey" size={24} />
						<Icon icon="website" size={24} />
						<Icon icon="phone" size={24} />
					</div>
					<div className={styles.emailWrapper}>
						<Icon icon="mail" size={24} className={styles.iconMail} />
						<Text variant="inter/14/medium" color="light_blue" className={styles.email}>
							contact@mail.com
						</Text>
					</div>
				</button>
			</ConfiguredTooltip>
			{isModalOpen && <ContactModal onClose={closeModal} id={id!} variant={variant} />}
			{notEnoughCreditsModalOpen && <NoCreditsModal onClose={closeIsNotEnoughCreditsModalOpen} />}
		</>
	);
};
