import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	text: string;
	onClick: (e: MouseEvent) => void;
	card?: boolean;
}

const formatTag = (text: string) => {
	if (text.length > 17) {
		return text.slice(0, 17) + '...';
	}
	return text;
};

export const SearchTag = ({ text, onClick, card }: Props) => {
	return (
		<div className={cn(styles.tag, { [styles.tagFull]: card })}>
			<Text variant="inter/14/medium" color="light_blue">
				{card ? text : formatTag(text)}
			</Text>
			<Icon icon="close" size={card ? 20 : 16} className={styles.tagClose} onClick={onClick} />
		</div>
	);
};
