import { ConfigProvider, Tooltip } from 'antd';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { SearchSelect } from 'features/search/components';
import { useGetProgrammingLanguagesQuery, useGetRepositoriesQuery, useGetSkillsQuery } from 'services';

import styles from './index.module.scss';

interface Props {
	activeTab: 'github' | 'stackoverflow' | 'kaggle';
}

export const SearchBy = ({ activeTab }: Props) => {
	const tooltipPlacement = 'right';

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<div
				className={cn(styles.wrapper, {
					[styles.wrapperStackoverflow]: activeTab === 'stackoverflow'
				})}>
				<div>
					{activeTab === 'github' && (
						<div>
							<div className={cn(styles.field, styles.fieldFirst)}>
								<SearchSelect
									label={
										<div className={styles.label}>
											<Text variant="inter/15/medium">Skills</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Use multiple skills to&nbsp;describe your perfect candidate.
															Skills are more detailed than programming languages, include
															technical concepts, names of&nbsp;specific technologies,
															libraries or&nbsp;areas of&nbsp;computer science like
															&lsquo;deep learning&rsquo; or &lsquo;computer
															vision&rsquo;, as&nbsp;an&nbsp;example.
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									className={styles.selectGithub}
									name="required_skills"
									useQuery={useGetSkillsQuery}
									placeholder="Enter or select Required skills"
									type="github"
								/>
							</div>
							<div className={styles.field}>
								<SearchSelect
									label={
										<div className={styles.label}>
											<Text variant="inter/15/medium">Programming languages</Text>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Programming languages are Python, C++, JavaScript, etc.
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									}
									className={styles.selectGithub}
									name="prog_langs"
									useQuery={useGetProgrammingLanguagesQuery}
									placeholder="Enter or select Programming Languages"
									type="github"
								/>
							</div>
							<div className={styles.field}>
								<SearchSelect
									className={styles.selectGithub}
									label="GitHub Repositories"
									name="github_repos"
									useQuery={useGetRepositoriesQuery}
									placeholder="Select GitHub Repositories"
									type="github"
								/>
							</div>
						</div>
					)}
					{activeTab === 'stackoverflow' && (
						<div className={cn(styles.field, styles.fieldFirst)}>
							<SearchSelect
								label={<Text variant="inter/15/medium">Stack Overflow skills</Text>}
								className={styles.selectStackoverflow}
								name="so_skills"
								useQuery={useGetSkillsQuery}
								placeholder="Enter or select Stack Overflow skills"
								type="stackoverflow"
							/>
						</div>
					)}
				</div>
			</div>
		</ConfigProvider>
	);
};
