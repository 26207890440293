import { FC, useState, useEffect } from 'react';
import { ConfigProvider, Form, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import { Button, Checkbox, FormikCheckbox, FormikCheckboxGroup, Icon, Text } from 'shared/components/ui';
import { SearchBy, SearchSelect, SearchTag } from 'features/search/components';
import {
	useGetCompaniesQuery,
	useGetCountriesQuery,
	useGetLanguagesQuery,
	useGetLocationsQuery,
	useGetRegionsQuery,
	useGetSchoolsQuery,
	useGetSubregionsQuery
} from 'services';
import { SeniorityEnum, YoEmploymentEnum, YoExperienceEnum } from 'shared/generated-models';
import { SearchFormParams } from 'features/search/models';
import { AdaptiveDevices, formIsEmpty, getAdaptiveDevice } from 'shared/utils';
import {
	GITHUB_FILTERS,
	STACKOVERFLOW_FILTERS,
	LOCATION_FILTERS,
	EXPERIENCE_ARRAY_FILTERS,
	EXPERIENCE_VALUE_FILTERS,
	EDUCATION_ARRAY_FILTERS,
	EDUCATION_VALUE_FILTERS,
	LANGUAGES_ARRAY_FILTERS,
	LANGUAGES_VALUE_FILTERS,
	parseGithubFilters,
	parseStackoverflowFilters,
	parseLocationFilters,
	parseExperienceFilters,
	parseEducationFilters,
	parseLanuagesFilters
} from 'shared/utils';
import { SearchParams } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	variant?: 'modal' | 'main';
}

interface TabProps {
	text: string;
	isActive: boolean;
	onClick: any;
	handleRemoveFilter: any;
	tags: [string, string][];
	clearAllFilters: (e: MouseEvent) => void;
}

interface SocialTabProps {
	type: 'github' | 'stackoverflow' | 'kaggle';
	icon: string;
	text: string;
	activeTab: string;
	onClick: any;
	isDisabled?: boolean;
	handleRemoveFilter: any;
	tags: [string, string][];
	clearAllFilters: (e: MouseEvent) => void;
}

const Tab = ({ text, isActive, onClick, handleRemoveFilter, tags, clearAllFilters }: TabProps) => {
	return (
		<div
			className={cn(
				styles.tab,
				{ [styles.tabActive]: isActive },
				{ [styles.tabBorder]: tags.length > 0 && !isActive }
			)}
			onClick={onClick}>
			<div className={styles.inlineExpand}>
				<Text className={styles.tabSpan} variant="inter/15/medium" color="grey">
					{text}
				</Text>
				{tags.length > 0 && (
					<div className={styles.tagAll}>
						<Text variant="inter/14/medium">{tags.length}</Text>
						<Icon icon="close" size={20} className={styles.tagCloseBig} onClick={clearAllFilters} />
					</div>
				)}
			</div>
			{tags.length > 0 && (
				<div className={styles.tags}>
					<>
						{tags.map(([tag, filter]) => (
							<SearchTag key={`${filter}: ${tag}`} text={tag} onClick={handleRemoveFilter(tag, filter)} />
						))}
					</>
				</div>
			)}
		</div>
	);
};

const SocialTab = ({
	type,
	icon,
	text,
	activeTab,
	onClick,
	isDisabled,
	tags,
	handleRemoveFilter,
	clearAllFilters
}: SocialTabProps) => {
	return (
		<div
			onClick={onClick}
			className={cn(
				styles.socialTab,
				{ [styles.socialTabActive]: activeTab === 'github' && type === 'github' },
				{ [styles.socialTabActiveStackoverflow]: activeTab === 'stackoverflow' && type === 'stackoverflow' },
				{ [styles.socialTabActiveKaggle]: activeTab === 'kaggle' && type === 'kaggle' },
				{ [styles.socialTabDisabled]: isDisabled }
			)}>
			<div className={styles.inlineExpand}>
				<div className={styles.inline}>
					<div className={styles.socialTabLogo}>
						<Icon icon={icon} size={20} />
					</div>
					<Text variant="inter/15/medium" color="black">
						{text} {isDisabled && <span className={styles.coming}>(soon)</span>}
					</Text>
				</div>
				{tags.length > 0 && (
					<div className={styles.tagAll}>
						<Text variant="inter/14/medium">{tags.length}</Text>
						<Icon icon="close" size={20} className={styles.tagCloseBig} onClick={clearAllFilters} />
					</div>
				)}
			</div>
			{tags.length > 0 && (
				<div className={styles.tags}>
					<>
						{tags.map(([tag, filter]) => (
							<SearchTag key={`${filter}: ${tag}`} text={tag} onClick={handleRemoveFilter(tag, filter)} />
						))}
					</>
				</div>
			)}
		</div>
	);
};

export const SearchFormV2: FC<Props> = ({ variant }: Props) => {
	const device = getAdaptiveDevice();
	const [activeTab, setActiveTab] = useState<string>('github');
	const [githubTags, setGithubTags] = useState<[string, string][]>([]);
	const [stackoverflowTags, setStackoverflowTags] = useState<[string, string][]>([]);
	const [kaggleTags, setKaggleTags] = useState<[string, string][]>([]);
	const [locationTags, setLocationTags] = useState<[string, string][]>([]);
	const [experienceTags, setExperienceTags] = useState<[string, string][]>([]);
	const [educationTags, setEducationTags] = useState<[string, string][]>([]);
	const [languageTags, setLanguageTags] = useState<[string, string][]>([]);

	const isSmallDevice = device === AdaptiveDevices.MOBILE || device === AdaptiveDevices.TABLET;
	const tooltipPlacement = isSmallDevice ? 'top' : 'right';

	const { values, setValues, validateForm, handleSubmit } = useFormikContext<SearchFormParams>();

	useEffect(() => {
		const tags: [string, string][] = parseGithubFilters(values);
		setGithubTags(tags);
	}, [values]);
	useEffect(() => {
		const tags: [string, string][] = parseStackoverflowFilters(values);
		setStackoverflowTags(tags);
	}, [values]);
	useEffect(() => {
		const tags: [string, string][] = parseLocationFilters(values);
		setLocationTags(tags);
	}, [values]);
	useEffect(() => {
		const tags: [string, string][] = parseExperienceFilters(values);
		setExperienceTags(tags);
	}, [values]);
	useEffect(() => {
		const tags: [string, string][] = parseEducationFilters(values);
		setEducationTags(tags);
	}, [values]);
	useEffect(() => {
		const tags: [string, string][] = parseLanuagesFilters(values);
		setLanguageTags(tags);
	}, [values]);

	const buttonsDisabled = formIsEmpty({ ...values, size: undefined, page: undefined });

	const handleClearFilters = () => {
		setValues({});
		validateForm({});
	};

	const handleRemoveFilter = (tag: string, filter: string) => (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		if (filter in ['likely_to_move_prob', 'is_top_school', 'is_first_name_female']) {
			//@ts-ignore
			newValues[filter as keyof SearchParams] = undefined;
		} else {
			if (
				filter in values &&
				//@ts-ignore
				Array.isArray(values[filter as keyof SearchParams]) &&
				//@ts-ignore
				(values[filter as keyof SearchParams] as any[]).length > 1
			) {
				// @ts-ignore
				newValues[filter as keyof SearchParams] = values[filter].filter(val => val !== tag);
			} else {
				if (filter === 'companies') newValues['is_current_companies'] = undefined;
				if (filter === 'companies_to_exclude') newValues['is_current_companies_to_exclude'] = undefined;
				//@ts-ignore
				newValues[filter as keyof SearchParams] = undefined;
			}
		}
		setValues(newValues, false);
	};

	const clearGithubFilters = (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		GITHUB_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		setValues(newValues);
	};
	const clearStackoverflowFilters = (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		STACKOVERFLOW_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		setValues(newValues);
	};
	const clearLocationFilters = (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		LOCATION_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		setValues(newValues);
	};
	const clearExperienceFilters = (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		EXPERIENCE_ARRAY_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		EXPERIENCE_VALUE_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		setValues(newValues);
	};
	const clearEducationFilters = (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		EDUCATION_ARRAY_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		EDUCATION_VALUE_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		setValues(newValues);
	};
	const clearLanguageFilters = (e: MouseEvent) => {
		e.stopPropagation();
		let newValues = { ...values };
		LANGUAGES_ARRAY_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		LANGUAGES_VALUE_FILTERS.forEach(filter => {
			// @ts-ignore
			if (filter in newValues) newValues[filter] = undefined;
		});
		setValues(newValues);
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<div className={styles.container}>
				<div className={styles.navigation}>
					<div className={styles.wrapper}>
						<div className={styles.wrapperTitle}>
							<Text variant="inter/15/medium" color="grey">
								Technical Requirements
							</Text>
						</div>
						<SocialTab
							icon="github-logo-circle"
							text="Github"
							type="github"
							activeTab={activeTab}
							onClick={() => setActiveTab('github')}
							handleRemoveFilter={handleRemoveFilter}
							tags={githubTags}
							clearAllFilters={clearGithubFilters}
						/>
						<SocialTab
							icon="stackoverflow-logo"
							text="Stack Overflow"
							type="stackoverflow"
							activeTab={activeTab}
							onClick={() => setActiveTab('stackoverflow')}
							handleRemoveFilter={handleRemoveFilter}
							tags={stackoverflowTags}
							clearAllFilters={clearStackoverflowFilters}
						/>
						<SocialTab
							icon="kaggle"
							text="Kaggle"
							type="kaggle"
							activeTab={activeTab}
							onClick={() => {}}
							isDisabled={true}
							handleRemoveFilter={handleRemoveFilter}
							tags={kaggleTags}
							clearAllFilters={() => {}}
						/>
					</div>
					<Tab
						text="Location"
						isActive={activeTab === 'location'}
						onClick={() => setActiveTab('location')}
						tags={locationTags}
						handleRemoveFilter={handleRemoveFilter}
						clearAllFilters={clearLocationFilters}
					/>
					<Tab
						text="Experience"
						isActive={activeTab === 'experience'}
						onClick={() => setActiveTab('experience')}
						tags={experienceTags}
						handleRemoveFilter={handleRemoveFilter}
						clearAllFilters={clearExperienceFilters}
					/>
					<Tab
						text="Education"
						isActive={activeTab === 'education'}
						onClick={() => setActiveTab('education')}
						tags={educationTags}
						handleRemoveFilter={handleRemoveFilter}
						clearAllFilters={clearEducationFilters}
					/>
					<Tab
						text="Languages and Diversity"
						isActive={activeTab === 'languages'}
						onClick={() => setActiveTab('languages')}
						tags={languageTags}
						handleRemoveFilter={handleRemoveFilter}
						clearAllFilters={clearLanguageFilters}
					/>
				</div>
				<Form layout="vertical" className={styles.form}>
					<div>
						{['github', 'stackoverflow', 'kaggle'].includes(activeTab) && (
							<SearchBy activeTab={activeTab as 'github' | 'stackoverflow' | 'kaggle'} />
						)}
						{activeTab === 'location' && (
							<div>
								<div className={styles.field}>
									<SearchSelect
										label="City or state"
										name="locations"
										useQuery={useGetLocationsQuery}
										placeholder="Enter City or state"
									/>
								</div>
								<div className={styles.field}>
									<SearchSelect
										label="Country"
										name="countries"
										useQuery={useGetCountriesQuery}
										placeholder="Enter Country"
									/>
								</div>
								<div className={styles.field}>
									<SearchSelect
										label="Region"
										name="regions"
										useQuery={useGetRegionsQuery}
										placeholder="Enter Region"
									/>
								</div>
								<div className={styles.field}>
									<SearchSelect
										label="Subregion"
										name="sub_regions"
										useQuery={useGetSubregionsQuery}
										placeholder="Enter Subregion"
									/>
								</div>
							</div>
						)}
						{activeTab === 'experience' && (
							<div>
								<div className={styles.field}>
									<FormikCheckbox
										label={
											<div className={styles.label}>
												<Text variant="inter/15/medium">Likely to move</Text>
												<Tooltip
													arrow={false}
													title={
														<div className={styles.tooltip}>
															<Text color="dark_green">
																Show candidates who are more likely to respond to new
																job opportunities
															</Text>
														</div>
													}
													placement={tooltipPlacement}
													overlayClassName={styles.labelTooltip}>
													<Icon icon="info" className={styles.labelIcon} />
												</Tooltip>
											</div>
										}
										name="likely_to_move_prob">
										Likely to move
									</FormikCheckbox>
								</div>
								<div className={styles.field}>
									<FormikCheckboxGroup
										label={<Text variant="inter/15/medium">Seniority</Text>}
										name="seniority">
										<Checkbox value={SeniorityEnum.SENIOR}>Senior</Checkbox>
										<Checkbox value={SeniorityEnum.MIDDLE}>Middle</Checkbox>
										<Checkbox value={SeniorityEnum.JUNIOR}>Junior</Checkbox>
										<div className={styles.rockstar}>
											<Checkbox value={SeniorityEnum.ROCK_STAR}>
												<span className={styles.rockstarLabel}>Rock Star</span>
											</Checkbox>
											<Tooltip
												arrow={false}
												title={
													<div className={styles.tooltip}>
														<Text color="dark_green">
															Rock stars are famous experts in their areas, such as Dan
															Abramov or Vitalik Buterin.
														</Text>
													</div>
												}
												placement={tooltipPlacement}
												overlayClassName={styles.labelTooltip}>
												<Icon icon="info" className={styles.labelIcon} />
											</Tooltip>
										</div>
									</FormikCheckboxGroup>
								</div>
								<div className={styles.field}>
									<div className={styles.row}>
										<FormikCheckboxGroup
											name="yo_employment"
											label={
												<div className={cn(styles.label, styles.rockstarLabel)}>
													<Text variant="inter/15/medium">
														Years of employment in software
													</Text>
													<Tooltip
														arrow={false}
														title={
															<div className={styles.tooltip}>
																<Text color="dark_green">
																	Based on their work experience on Linkedin
																</Text>
															</div>
														}
														placement={tooltipPlacement}
														overlayClassName={styles.labelTooltip}>
														<Icon icon="info" className={styles.labelIcon} />
													</Tooltip>
												</div>
											}>
											<Checkbox value={YoEmploymentEnum._10_}>10+</Checkbox>
											<Checkbox value={YoEmploymentEnum._5_10}>5-10</Checkbox>
											<Checkbox value={YoEmploymentEnum._3_5}>3-5</Checkbox>
											<Checkbox value={YoEmploymentEnum._0_3}>0-3</Checkbox>
										</FormikCheckboxGroup>
										<FormikCheckboxGroup
											name="yo_experience"
											label={
												<div className={cn(styles.label, styles.rockstarLabel)}>
													<Text variant="inter/15/medium">Years of coding experience</Text>
													<Tooltip
														arrow={false}
														title={
															<div className={styles.tooltip}>
																<Text color="dark_green">
																	Based on their contributions on Github
																</Text>
															</div>
														}
														placement={tooltipPlacement}
														overlayClassName={styles.labelTooltip}>
														<Icon icon="info" className={styles.labelIcon} />
													</Tooltip>
												</div>
											}>
											<Checkbox value={YoExperienceEnum._10_}>10+</Checkbox>
											<Checkbox value={YoExperienceEnum._5_10}>5-10</Checkbox>
											<Checkbox value={YoExperienceEnum._3_5}>3-5</Checkbox>
											<Checkbox value={YoExperienceEnum._0_3}>0-3</Checkbox>
										</FormikCheckboxGroup>
									</div>
								</div>
								<div className={styles.field}>
									<SearchSelect
										label="Companies"
										name="companies"
										useQuery={useGetCompaniesQuery}
										placeholder="Enter company name or domain"
									/>
									<FormikCheckbox name="is_current_companies">
										<Text variant="inter/14/regular" color="grey">
											Currently working in the company
										</Text>
									</FormikCheckbox>
								</div>
								<div className={styles.field}>
									<SearchSelect
										label="Excluded companies"
										name="companies_to_exclude"
										useQuery={useGetCompaniesQuery}
										placeholder="Enter or select company name or domain to exclude from search"
									/>
									<FormikCheckbox name="is_current_companies_to_exclude">
										<Text variant="inter/14/regular" color="grey">
											Currently not working in the company
										</Text>
									</FormikCheckbox>
								</div>
							</div>
						)}
						{activeTab === 'education' && (
							<div>
								<div className={styles.field}>
									<FormikCheckbox
										label={
											<div className={styles.label}>
												<Text variant="inter/15/medium">Top Schools</Text>
												<Tooltip
													arrow={false}
													title={
														<div className={styles.tooltip}>
															<Text color="dark_green">
																Find candidates who graduated from top schools in
																computer science
															</Text>
														</div>
													}
													placement={tooltipPlacement}
													overlayClassName={styles.labelTooltip}>
													<Icon icon="info" className={styles.labelIcon} />
												</Tooltip>
											</div>
										}
										name="is_top_school">
										Top schools in computer science
									</FormikCheckbox>
								</div>
								<div className={styles.field}>
									<SearchSelect
										label="Education"
										name="schools"
										useQuery={useGetSchoolsQuery}
										placeholder="Enter or select Schools, Universities"
									/>
								</div>
							</div>
						)}
						{activeTab === 'languages' && (
							<div>
								<div className={styles.field}>
									<SearchSelect
										label="Languages"
										name="languages"
										useQuery={useGetLanguagesQuery}
										placeholder="Enter or select Languages"
									/>
								</div>
								<div className={styles.field}>
									<FormikCheckbox
										label={
											<div className={styles.label}>
												<Text variant="inter/15/medium">Diversity filters</Text>
											</div>
										}
										name="is_first_name_female">
										Name contains a female first name
									</FormikCheckbox>
								</div>
							</div>
						)}
					</div>
					{variant === 'modal' && (
						<div className={styles.formButtons}>
							<Button
								className={styles.formButton}
								disabled={buttonsDisabled}
								onClick={handleClearFilters}>
								Clear filters
							</Button>
							<Button
								className={styles.formButton}
								type="primary"
								disabled={buttonsDisabled}
								onClick={handleSubmit}>
								Show candidates
							</Button>
						</div>
					)}
				</Form>
			</div>
		</ConfigProvider>
	);
};
