import { FC } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';
import cn from 'classnames';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, FormikInput, Icon, Text } from 'shared/components/ui';
import { useSaveHistoryMutation } from 'services';
import { ProjectCreate } from 'shared/generated-models';
import { useGetCandidates } from 'features/search/hooks';
import { useFilters } from 'features/search/hooks';
import { isPlural } from 'shared/utils';
import { openNotification } from 'shared/components/ui';
import { saveHistoryFormValidationSchema } from 'features/search/models';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
}

export const SaveSearchModal: FC<Props> = ({ onClose }: Props) => {
	const [saveHistory, { isLoading }] = useSaveHistoryMutation();

	const { data } = useGetCandidates();

	const filters = useFilters();

	const formik = useFormik<ProjectCreate>({
		validationSchema: saveHistoryFormValidationSchema,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: { name: '' },
		onSubmit: async values => {
			try {
				await saveHistory({
					name: values.name,
					count: data.results_count!,
					filters: filters
				});
				openNotification({ text: 'Search saved!' });
				onClose();
			} catch (e: any) {
				if (e.data && e.data.name) {
					formik.setErrors({ name: 'Project with this name already exists' });
					return;
				}
			}
		}
	});

	return (
		<CenterModal onClose={onClose} icon="history" title="Save search" iconColor="green">
			<div className={styles.body}>
				<FormikProvider value={formik}>
					<Form layout="vertical">
						<FormikInput name="name" size="large" placeholder="name" label="Name" />
					</Form>
				</FormikProvider>
				{data && (
					<div
						className={cn(styles.count, {
							[styles.countMargin]: true,
							[styles.countError]: formik.errors.name
						})}>
						<Icon icon="person" className={styles.countIcon} />
						<Text variant="inter/14/medium">
							{data.results_count} {isPlural(data.results_count!) ? 'candidates' : 'candidate'}
						</Text>
					</div>
				)}
			</div>
			<ModalFooter>
				<Button onClick={onClose} type="ghost">
					Cancel
				</Button>
				<Button onClick={formik.handleSubmit} type="primary" disabled={!formik.values.name || isLoading}>
					Save
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
