import { FC } from 'react';
import cn from 'classnames';
import { ConfigProvider } from 'antd';

import { ProgAIResults, ProgAICandidate } from 'shared/generated-models';
import { ReadMoreText } from 'shared/components';
import { Icon, openNotification, Text } from 'shared/components/ui';
import { CandidateMatched } from 'features/candidate';
import { formatEducation } from 'features/candidate/utils';
import { ProfileVariant } from 'features/candidate';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
	metadata?: ProgAIResults['match_metadata'];
	shared?: boolean;
	variant: ProfileVariant;
}

export const CandidateInfo: FC<Props> = ({ data, metadata, shared, variant }: Props) => {
	const isSenior = data.seniority_level === 'Rock Star' || data.seniority_level === 'Senior';
	const fromTopSchool = data.schools && data.schools.length > 0;
	const hasHighlights = isSenior || fromTopSchool;

	const handleRate = () => {
		openNotification({ text: 'Thanks for the feedback!', closable: true });
	};

	const openTopExpertsPage = (url?: string) => () => {
		window.open(url, '_blank');
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: {
						paddingSM: 0,
						paddingXS: 0,
						borderRadius: 2,
						colorBgDefault: '#f8f8f8'
					}
				}
			}}>
			<section className={styles.info}>
				<div className={styles.header}>
					<Text variant="inter/18/semi" component="h3">
						Summary
					</Text>
					{variant === 'search' && (
						<div className={styles.rate}>
							<Text variant="inter/14/regular" color="grey">
								Looks like a good fit?
							</Text>
							<div className={styles.rateIcon} onClick={handleRate}>
								<Icon size={20} icon="thumbs-up" />
							</div>
							<div className={styles.rateIcon} onClick={handleRate}>
								<Icon size={20} icon="thumbs-down" />
							</div>
						</div>
					)}
				</div>
				{data.top_expert_in && data.top_expert_in.length > 0 && (
					<div className={styles.topExpertWrapper}>
						<Icon icon="award" size={20} className={styles.icon} />
						<div className={styles.column}>
							<Text variant="inter/14/regular" color="darker_grey">
								Top expert in
							</Text>
							{data.top_expert_in?.map(top_exert => (
								<span onClick={openTopExpertsPage(top_exert.url)} style={{ cursor: 'pointer' }}>
									<Text variant="inter/14/medium" color="light_blue">
										{top_exert.name}
									</Text>
								</span>
							))}
						</div>
					</div>
				)}
				{hasHighlights && (
					<div className={styles.infoHighlights}>
						{isSenior && (
							<Text variant="inter/14/medium" color="black" className={styles.infoHighlightsItem}>
								<div className={styles.emoji}>👤</div>
								Senior
							</Text>
						)}
						{fromTopSchool && (
							<Text variant="inter/14/medium" color="black" className={styles.infoHighlightsItem}>
								<div className={styles.emoji}>🎓</div>
								Top School
							</Text>
						)}
					</div>
				)}
				<div className={shared ? styles.infoItemsShared : styles.infoItems}>
					<ReadMoreText
						key={data.bio || data.summary || ''}
						className={styles.bio}
						text={data.bio || data.summary || ''}
					/>
					{!!data.years_of_experience && (
						<div className={styles.row}>
							<Icon icon="code" size={20} className={styles.icon} />
							<Text variant="inter/14/regular" color="darker_grey">
								{data.years_of_experience} {data.years_of_experience > 1 ? 'years' : 'year'} of coding
								experience
							</Text>
						</div>
					)}
					{!!data.years_of_employment && (
						<div className={styles.row}>
							<Icon icon="job" size={20} className={styles.icon} />
							<Text variant="inter/14/regular" color="darker_grey">
								{data.years_of_employment} {data.years_of_employment > 1 ? 'years' : 'year'} of
								employment as a software developer
							</Text>
						</div>
					)}
					{data.schools &&
						data.schools.length > 0 &&
						data.schools.map(school => (
							<div className={styles.row} key={formatEducation(school, data)}>
								<Icon icon="book" size={20} className={styles.icon} />
								<Text variant="inter/14/regular" color="darker_grey" className={styles.education}>
									{formatEducation(school, data)}
								</Text>
							</div>
						))}
					{data.languages && (
						<div className={styles.row}>
							<Icon icon="languages" size={20} className={styles.icon} />
							<Text variant="inter/14/regular" color="darker_grey">
								{data.languages.join(', ')}
							</Text>
						</div>
					)}
				</div>
				{metadata && metadata.found_skills && (
					<div className={styles.infoMatched}>
						<Text variant="inter/14/medium" color="black">
							Matched skills
						</Text>
						<CandidateMatched metadata={metadata} />
					</div>
				)}
			</section>
		</ConfigProvider>
	);
};
