export const AnalyticsEvents = {
	REGISTRATION: 'Sign up',
	LOGIN: 'Log in',
	SEARCH: 'Search',
	OPEN_PROFILE: 'Opened profile',
	EXPORT: 'Export',
	EXPORT_GOOGLE_SHEETS: 'Export Google Sheets',
	SHARE_PROFILE: 'Sharing profile',
	OPEN_SHARED_PROFILE: 'Opening shared profile',
	SUBSCRIPTION: 'Subscription',
	CLICKED_ON_FACEBOOK: 'Clicked on Facebook',
	CLICKED_ON_STACKOVERFLOW: 'Clicked on Stackoverflow',
	CLICKED_ON_KAGGLE: 'Clicked on Kaggle',
	CLICKED_ON_HOMEPAGE: 'Clicked on Homepage',
	CLICKED_ON_GITHUB: 'Clicked on GitHub',
	CLICKED_ON_LINKEDIN: 'Clicked on LinkedIn',
	CLICKED_ON_TWITTER: 'Clicked on Twitter',
	COPIED_EMAIL: 'Copied email',
	COPIED_PHONE: 'Copied phone number',
	GET_CONTACT: 'Get contact',
	ADD_TO_PROJECT: 'Add to project',
	PUSH_TO_OUTREACH: 'Push to outreach',
	PARSED_TEXT: 'Parsed text',
	UPGRADE_CLICKED: 'Upgrade clicked',
	TOP_EXPERTS_CLICKED: 'Top Experts clicked',
	CHROME_EXTENSION_CLICKED: 'Chrome Extension clicked',
	LOGIN_PAGE_VIEW: 'View Login page',
	SIGIN_PAGE_VIEW: 'View Signin page',
	SIGNUP_PAGE_VIEW: 'View Signup page',
	REGISTRATION_FORM_PAGE_VIEW: 'View Registration complete page',
	VERFIY_EMAIL_PAGE_VIEW: 'View Verify email page',
	CLICKED_ON_GOOGLE_SIGN_IN: 'Clicked on Google oauth',
	CLICKED_ON_MICROSOFT_SIGN_IN: 'Clicked on Microsoft oauth',
	CLICKED_ON_CONTINUE_WITH_EMAIL: 'Clicked on Continue with email',
	CLICKED_ON_FORGOT_PASSWORD: 'Clicked on Forgot password',
	CLICKED_SIGN_IN_BUTTON: 'Clicked Sign In button',
	CLICKED_SIGN_UP_BUTTON: 'Clicked Sign Up button'
} as const;
