import { SearchParams } from 'shared/generated-models';

const ARRAY_FILTERS = [
	'locations',
	'countries',
	'regions',
	'sub_regions',
	'seniority',
	'yo_employment',
	'yo_experience',
	'companies',
	'companies_to_exclude',
	'required_skills',
	'bonus_skills',
	'prog_langs',
	'github_repos',
	'schools',
	'languages',
	'so_skills'
];

const VALUE_FILTERS = ['likely_to_move_prob', 'is_top_school', 'is_first_name_female'];
const VALUE_FILTERS_MAPPING = {
	likely_to_move_prob: 'Likely to move',
	is_top_school: 'Top Schools',
	is_first_name_female: 'First name female'
};

export const GITHUB_FILTERS = ['required_skills', 'bonus_skills', 'prog_langs', 'github_repos'];
export const STACKOVERFLOW_FILTERS = ['so_skills'];
export const LOCATION_FILTERS = ['locations', 'countries', 'regions', 'sub_regions'];
export const EXPERIENCE_ARRAY_FILTERS = [
	'seniority',
	'yo_employment',
	'yo_experience',
	'companies',
	'companies_to_exclude'
];
export const EXPERIENCE_VALUE_FILTERS = ['likely_to_move_prob'];
export const EDUCATION_ARRAY_FILTERS = ['schools'];
export const EDUCATION_VALUE_FILTERS = ['is_top_school'];
export const LANGUAGES_ARRAY_FILTERS = ['languages'];
export const LANGUAGES_VALUE_FILTERS = ['is_first_name_female'];

export const parseFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	ARRAY_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});
	VALUE_FILTERS.forEach((filter: string) => {
		if (filter in filters && filters[filter as keyof typeof filters]) {
			resultArray.push([VALUE_FILTERS_MAPPING[filter as keyof typeof VALUE_FILTERS_MAPPING], filter]);
		}
	});

	return resultArray;
};

export const parseGithubFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	GITHUB_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});

	return resultArray;
};
export const parseStackoverflowFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	STACKOVERFLOW_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});

	return resultArray;
};
export const parseLocationFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	LOCATION_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});

	return resultArray;
};
export const parseExperienceFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	EXPERIENCE_ARRAY_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});
	EXPERIENCE_VALUE_FILTERS.forEach((filter: string) => {
		if (filter in filters && filters[filter as keyof typeof filters]) {
			resultArray.push([VALUE_FILTERS_MAPPING[filter as keyof typeof VALUE_FILTERS_MAPPING], filter]);
		}
	});

	return resultArray;
};
export const parseEducationFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	EDUCATION_ARRAY_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});
	EDUCATION_VALUE_FILTERS.forEach((filter: string) => {
		if (filter in filters && filters[filter as keyof typeof filters]) {
			resultArray.push([VALUE_FILTERS_MAPPING[filter as keyof typeof VALUE_FILTERS_MAPPING], filter]);
		}
	});

	return resultArray;
};
export const parseLanuagesFilters = (filters: SearchParams) => {
	let resultArray: [string, string][] = [];

	LANGUAGES_ARRAY_FILTERS.forEach((filter: string) => {
		if (filter in filters && Array.isArray(filters[filter as keyof typeof filters])) {
			resultArray = resultArray.concat(
				(filters[filter as keyof typeof filters] as string[]).map((value: string) => [value, filter])
			);
		}
	});
	LANGUAGES_VALUE_FILTERS.forEach((filter: string) => {
		if (filter in filters && filters[filter as keyof typeof filters]) {
			resultArray.push([VALUE_FILTERS_MAPPING[filter as keyof typeof VALUE_FILTERS_MAPPING], filter]);
		}
	});

	return resultArray;
};
