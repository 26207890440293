import { MainHistory } from 'features/search';
import { ExpiredPlanModal, useIsExpired } from 'features/auth';

export const History = () => {
	const isExpired = useIsExpired();

	return (
		<>
			<MainHistory />
			{isExpired && <ExpiredPlanModal />}
		</>
	);
};
