import { useEffect } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { Text } from 'shared/components/ui';
import { SearchTable } from 'features/search/components';

import styles from './index.module.scss';

export const MainHistory = () => {
	useEffect(() => {
		const htmlElement = document.documentElement;
		htmlElement.style.overflowY = 'scroll';

		return () => {
			htmlElement.style.overflowY = '';
		};
	}, []);

	return (
		<>
			<div className={cn(styles.navigation, styles.navigationFilters)}>
				<div className={styles.navigationContainer}>
					<nav className={styles.tabs}>
						<div className={cn(styles.tab, styles.tabFilters)}>
							<Text variant="grotesk/20/medium" className={styles.tabText} color={'black'}>
								Searches
							</Text>
						</div>
					</nav>
				</div>
			</div>
			<div className={styles.container}>
				<AnimatePresence mode="wait">
					<motion.div
						key="search"
						initial={{ x: 100, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: 100, opacity: 0 }}
						transition={{ bounce: 0, duration: 0.2 }}>
						<SearchTable />
					</motion.div>
				</AnimatePresence>
			</div>
		</>
	);
};
