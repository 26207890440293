import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const TableSkeleton: FC<Props> = ({ className, children, ...otherProps }) => {
	return (
		<section {...otherProps} className={cn(styles.skeleton, className)}>
			{[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
				<div key={item} className={styles.skeletonRow}>
					<div className={styles.skeletonText}>
						<div className={styles.skeletonData}>
							<SkeletonRow width={144} />
							<SkeletonRow width={180} />
							<SkeletonRow width={164} />
							<SkeletonRow width={144} />
							<SkeletonRow width={164} />
						</div>
					</div>
					{/* </div> */}
				</div>
			))}
		</section>
	);
};
