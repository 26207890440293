import { useState } from 'react';

import { TextButton, Text, Icon } from 'shared/components/ui';

import styles from './index.module.scss';

const POPULAR_SEARCHES = [
	{
		id: 1,
		title: 'Senior machine learning engineers',
		body: 'in California with knowledge of LLMs',
		link: '/search/results?locations[]=California&page=0&seniority[]=Senior&size=100&text=Senior%20machine%20learning%20engineers%20with%20knowledge%20of%20LLMs%0A'
	},
	{
		id: 2,
		title: 'DevOps engineers',
		body: 'in the LATAM with at least 5 years of experience',
		link: '/search/results?page=0&size=100&sub_regions[]=Latin%20America%20and%20the%20Caribbean&text=DevOps%20engineers%20with%20experience%20with%20kubernetes&yo_employment[]=5-10,10%2B'
	},
	{
		id: 3,
		title: 'Self-driving cars experts',
		body: 'who are more likely to change their jobs soon',
		link: '/search/results?countries[]=United%20States&likely_to_move_prob=true&page=0&size=100&text=Self-driving%20cars%20experts'
	},
	{
		id: 4,
		title: 'Female data engineer, Stanford graduates',
		// body: 'In Eastern Europe ',
		link: '/search/results?is_first_name_female=true&page=0&size=100&text=data%20engineer&schools[]=Stanford%20University'
	},
	{
		id: 5,
		title: 'React.js engineers',
		body: 'in Latin America',
		link: '/search/results?page=0&size=100&sub_regions[]=Latin%20America%20and%20the%20Caribbean&text=React.js%20engineers'
	},
	{
		id: 6,
		title: 'Full-stack developers',
		body: 'in San Francisco ',
		link: '/search/results?locations[]=San%20Francisco%2C%20California&page=0&size=100&text=Full-stack%20engineers'
	},
	{
		id: 7,
		title: 'All open source contributors to Hugging Face',
		// body: 'Worldwide',
		link: '/search/results?github_repos[]=huggingface%2Ftransformers,huggingface%2Fpytorch-image-models,huggingface%2Fdiffusers,huggingface%2Fdatasets,huggingface%2Fpeft,huggingface%2Fcandle,huggingface%2Ftrl,huggingface%2Fnotebooks,huggingface%2Ftext-generation-inference,huggingface%2Ftokenizers,huggingface%2Fchat-ui,huggingface%2Faccelerate,huggingface%2Fdeep-rl-class,huggingface%2Fautotrain-advanced,huggingface%2Fneuralcoref&page=0&size=100'
	},
	{
		id: 8,
		title: 'Go engineers',
		body: 'in the UK',
		link: '/search/results?countries[]=United%20Kingdom&page=0&required_skills[]=go,golang&size=100&text=go%20engineers'
	},
	{
		id: 9,
		title: 'Rust engineers',
		body: 'in Eastern Europe',
		link: '/search/results?page=0&size=100&sub_regions[]=Eastern%20Europe&text=Rust%20engineers'
	},
	{
		id: 10,
		title: 'Product manager from Perplexity AI',
		// body: 'in Eastern Europe',
		link: '/search/results?companies[]=Perplexity%20-%20perplexity.ai&page=0&size=100&text=Product%20managers'
	}
];

export const TrendingSearch = () => {
	const [open, setOpen] = useState(false);
	const searchItemsCount = POPULAR_SEARCHES.length;
	const maxItems = open ? searchItemsCount : 5;
	const buttonText = open ? 'See less' : `See all (${searchItemsCount})`;

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<Icon className={styles.icon} icon={'trending-up'} size={24} />
				<Text variant="inter/16/regular" color="grey">
					Popular searches
				</Text>
			</div>
			<div className={styles.card}>
				{POPULAR_SEARCHES.slice(0, maxItems).map(searchItem => (
					<a
						key={searchItem.id}
						href={searchItem.link}
						target="_blank"
						rel="noreferrer"
						className={styles.link}>
						<div className={styles.cardItem}>
							<Text variant="inter/16/medium" color="grey">
								{searchItem.id}.
							</Text>
							<div className={styles.cardText}>
								<Text variant="inter/16/medium" color="light_blue">
									{searchItem.title}
								</Text>
								<Text variant="inter/16/regular" color="grey" className={styles.text}>
									{searchItem.body}
								</Text>
							</div>
						</div>
					</a>
				))}
				<div className={styles.seeMore}>
					<Text className={styles.seeMoreText} variant="inter/14/regular" color="grey">
						<TextButton onClick={handleClick}>{buttonText}</TextButton>
					</Text>
				</div>
			</div>
			{/* <TrendingSearchSkeleton /> */}
		</div>
	);
};
